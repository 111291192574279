import { render, staticRenderFns } from "./Pours.vue?vue&type=template&id=5ad522b3&scoped=true&"
import script from "./Pours.vue?vue&type=script&lang=js&"
export * from "./Pours.vue?vue&type=script&lang=js&"
import style0 from "./Pours.vue?vue&type=style&index=0&id=5ad522b3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad522b3",
  null
  
)

export default component.exports